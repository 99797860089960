<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-5">
          <v-toolbar color="cyan">
            <v-toolbar-title>ログインください</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                v-model="username"
                prepend-icon="mdi-account"
                name="username"
                label="ユーザ名"
                type="text"
              ></v-text-field>
              <v-text-field
                v-model="password"
                prepend-icon="mdi-lock"
                id="password"
                name="password"
                label="パスワード"
                :type="passshow ? 'text' : 'password'"
                :append-icon="passshow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passshow = !passshow"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary">ログイン</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    username: '',
    password: '',
    passshow: false,
  }),
};
</script>

<style>
</style>